<div
	class="flex gap-3 rounded-dialog border-2 bg-white p-4 py-3"
	[ngClass]="{
		'border-feedback-info': typeSig() === dialogTypes.DEFAULT,
		'border-feedback-success': typeSig() === dialogTypes.SUCCESS,
		'border-feedback-warning': typeSig() === dialogTypes.WARNING,
		'border-feedback-error-500': typeSig() === dialogTypes.ERROR
	}"
>
	<ui-icon
		class="text-2xl leading-none"
		[ngClass]="{
			'text-feedback-info': typeSig() === dialogTypes.DEFAULT,
			'text-feedback-success': typeSig() === dialogTypes.SUCCESS,
			'text-feedback-warning': typeSig() === dialogTypes.WARNING,
			'text-feedback-error-500': typeSig() === dialogTypes.ERROR
		}"
		[name]="iconNameSig()"
	></ui-icon>
	<div class="flex flex-col justify-center">
		<p *ngIf="title" class="font-bold">{{ title }}</p>
		<div class="flex flex-col gap-2">
			<p *ngIf="text" class="text-sm">{{ text }}</p>
			<ng-content></ng-content>
		</div>
		<ng-container *ngIf="cta">
			<a [href]="cta.href" target="_blank" class="mt-2">
				<ui-button
					[styleType]="buttonStyleTypes.CTA"
					[fullWidth]="true"
				>
					{{ cta.text }}
				</ui-button>
			</a>
		</ng-container>
	</div>
</div>
