import { Component, Input, computed, signal } from '@angular/core';
import { ButtonStyleTypes } from 'ui-button';

export enum DialogTypes {
	DEFAULT = 'default',
	SUCCESS = 'success',
	WARNING = 'warning',
	ERROR = 'error',
}

@Component({
	selector: 'ui-dialog',
	templateUrl: './dialog.component.html',
})
export class DialogComponent {
	@Input() set type(value: DialogTypes) {
		this.typeSig.set(value);
	}
	@Input() title = '';
	@Input() text = '';
	@Input() cta?: { text: string; href: string };

	typeSig = signal(DialogTypes.DEFAULT);
	iconNameSig = computed(() => {
		switch (this.typeSig()) {
			case DialogTypes.ERROR:
				return 'error';
			case DialogTypes.WARNING:
				return 'warning';
			case DialogTypes.SUCCESS:
				return 'check_circle';
			default:
				return 'info';
		}
	});

	readonly buttonStyleTypes = ButtonStyleTypes;
	readonly dialogTypes = DialogTypes;
}
